/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-file-img {
  background-image: url(/ess/resource/mobile/common/images/sprite.png);
  background-position: 0px 0px;
  width: 37px;
  height: 48px;
}
.icon-file-news {
  background-image: url(/ess/resource/mobile/common/images/sprite.png);
  background-position: 0px -58px;
  width: 35px;
  height: 46px;
}
.icon-file-pdf {
  background-image: url(/ess/resource/mobile/common/images/sprite.png);
  background-position: -47px 0px;
  width: 36px;
  height: 46px;
}
.icon-file-zip {
  background-image: url(/ess/resource/mobile/common/images/sprite.png);
  background-position: -93px 0px;
  width: 36px;
  height: 46px;
}
.icon-lg-chem-ci-gray {
  background-image: url(/ess/resource/mobile/common/images/sprite.png);
  background-position: -45px -58px;
  width: 54px;
  height: 12px;
}
.icon-lg-chem-ci {
  background-image: url(/ess/resource/mobile/common/images/sprite.png);
  background-position: -45px -80px;
  width: 54px;
  height: 12px;
}

@media (-webkit-min-device-pixel-ratio: 2),
       (min-resolution: 192dpi) {
  .icon-file-img {
    background-image: url(/ess/resource/mobile/common/images/sprite@2x.png);
    background-size: 129px 104px;
  }
  .icon-file-news {
    background-image: url(/ess/resource/mobile/common/images/sprite@2x.png);
    background-size: 129px 104px;
  }
  .icon-file-pdf {
    background-image: url(/ess/resource/mobile/common/images/sprite@2x.png);
    background-size: 129px 104px;
  }
  .icon-file-zip {
    background-image: url(/ess/resource/mobile/common/images/sprite@2x.png);
    background-size: 129px 104px;
  }
  .icon-lg-chem-ci-gray {
    background-image: url(/ess/resource/mobile/common/images/sprite@2x.png);
    background-size: 129px 104px;
  }
  .icon-lg-chem-ci {
    background-image: url(/ess/resource/mobile/common/images/sprite@2x.png);
    background-size: 129px 104px;
  }
}
